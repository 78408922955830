/* Footer ============================================================================= */
#pagefooter {
  clear: both;
}

.SignUpFooterContainer {
  padding: spacing(lg) spacing();
  background: #E78042;
  background-image: linear-gradient(130deg, #EB8D32 0%, #EE6A34 37%, #EE6A34 37%, #EE6A34 56%, #EE6A34 73%, #DF7840 100%);

  .SocialAuth-label {
    color: color('white');
  }
}

/* @define GlobalFooter */
.GlobalFooter {
  width: 100%;
  margin: 0 auto;
  background: color('purple');
  clear: both;
  margin-top: 80px;
  overflow: hidden;
}

.GlobalFooter-copyright {
  text-align: right;
  float: right;
  padding: 16px;
}

.GlobalFooter-rmpLink {
  line-height: 1;

  &:hover,
  &:focus {
    color: color('white');
  }
}

.GlobalFooter-disclaimer {
  width: 100%;
  line-height: 20px;
  float: left;
  padding-bottom: $space-default;
  font-size: 13px;
  color: color('white');
  padding-left: 16px;
  padding-right: 16px;
  @include respond-to(md) {
    max-width: 70%;
  }
}

.GlobalFooter-social {
  float: right;
}

.GlobalFooter-socialLink {
  display: inline-block;
  line-height: 1;
  margin-left: 8px;
}

.GlobalFooter-socialLink {
  color: color('black');
  font-size: 35px;
  line-height: 45px;

  svg {
    width: 25px;
    height: 25px;
    fill: #FFF;
  }
}

.GlobalFooter-nav {
  float: left;
  width: 70%;
}

.GlobalFooter-navLists {
  clear: both;
  padding-bottom: spacing(lg);
}

.GlobalFooter-navList {
  float: left;
  margin-right: 14px;
  margin-left: 0;
}

.GlobalFooter-top {
  margin: 0;
  display: block;
  background: color('orange');
  margin-bottom: 1em;
  overflow: hidden;
  padding: 16px;
}

.GlobalFooter-navLink {
  color: color('white');
  display: block;
  height: 100%;
  position: relative;
  line-height: 46px;
  font-size: 16px;

  &:hover,
  &:focus {
    color: color('orange', 'light');
  }
}

.GlobalFooter-rmpLink {
  color: color('white');
}
