.duration-zone {
  margin-top: 0;
}
.Article{
  ul{
    @extend .List--bullet !optional;
  }
  ol{
    @extend .List--numeric !optional;
  }
  .cta{
    margin-bottom: spacing();
    margin-top: spacing();
  }
  .HotJob{
    margin-bottom: spacing();
  }
}
.Article-content{
  h1, h2, h3, h4, h5 {
    margin: 1.5em 0px 0.8em;
  }

  a, li, blockquote,
  p {
    font-size: 18px;
    letter-spacing: 0.12px;
    line-height: 1.6;
  }

  .HotJob {
    h3 {
      margin-top: 0;
      font-size: 1em;
      margin-bottom: spacing();
    }
  }

  .Subheading {
    font-size: 24px;
    line-height: 40px;
  }

  ul {
    list-style: disc;
    margin: 1rem;
  }

  .Article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.no-sidebar {
  width: 70%;
  margin: auto;
}

.quote {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  svg {
    min-width: 55px;

    @media screen and (max-width: 540px) {
      display: none;
    }
  }

  blockquote {
    color: black;
    letter-spacing: 0.26px;
    text-align: left;
    font-style: italic;
    margin-bottom: spacing();
    margin-top: 0;

    cite {
      display: block;
      font-style: normal;
      font-weight: bold;
      margin-top: spacing('sm');

      a {
        color: black;
      }
    }
  }
}

.Article-banner {
  /* background: color('grey', 'lightest'); */
  background: $homepagePrimary;
  padding-top: spacing(lg);
  margin-bottom: spacing(xl);
  @include respond-to(md) {
    padding-bottom: spacing(lg);
  }

  h1 {
    color: white;
    margin-top: 0;
    @include respond-to(sm) {
      font-size: 48px;
      line-height: 60px;
    }
  }

  p {
    color: white;
  }
}

.Article-bannerContent {
  flex-wrap: wrap;
  @include respond-to(md) {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
  }
}

.Article-image,
.Article-bannerCopy {
  width: 100%;
  padding: 0;
  @include respond-to(md) {
    width: 50%;
  }
}

.Article-bannerCopy {
  padding-right: spacing('lg');
  @include respond-to(md, max-width) {
    padding: spacing();
  }
}

.Article-image {
  font-size: 0;
  overflow: hidden;
  @include respond-to(xs) {
    border-radius: radius();
  }

  img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
}
