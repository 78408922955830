 // New pagination

 .Pagination {
   display: flex;
   margin: 2rem 0 1rem 0;
   justify-content: space-between;
   @include respond-to(md){
     justify-content: center;
   }
 }

 .Pagination-item {
   display: inline-block;
   vertical-align: middle;
   list-style: none;
 }

 .Pagination--pageNumber {
   display: none;
   @include respond-to(md) {
     display: block;
   }
 }

 .Pagination-button {
   width: 40px;
   height: 40px;
   background: color('grey', 'lightest');
   text-align: center;
   line-height: 40px;
   flex-shrink: 0;
   display: block;
   margin-left: -1px;
   border: 1px solid  color('grey', 'light');
   overflow-anchor: none;
   &.Pagination--arrowButton {
     width: 80px;
   }
   &:hover, &:focus {
     background:  color('grey', 'light');
     color:  color('green');
   }
   &.is-active,
   &.is-active:hover {
     background: color($primary);
     border-color: color($primary);
     color: color('white');
   }

 }

 .Pagination--right {
   border-radius: 0 4px 4px 0
 }

 .Pagination--left {
   border-radius: 4px 0 0 4px
 }

 .disabled {
   background: color('grey', 'lightest');
   color:  color('grey', 'light');
   &:hover, &:focus {
     color:  color('grey', 'light');
     background:  color('grey', 'light');
   }
 }



/* TABLE PAGINATION ============================================================================= */
.pagination, .dataTables_paginate {
  width: 100%;
  padding: spacing();
  float: left;
  //margin: 20px 0;
  text-align: center;
  //border-top: 1px solid #efede9;
  //border-bottom: 1px solid #efede9;
  &:after, &:before {
    display: table;
    width: 100%;
    clear: both;
    content: '';
  }
}

.profile-content2 {
  .pagination, .dataTables_paginate {
    border: none;
  }
}

.pagination li, .pagination li a, .dataTables_paginate a, .pagination li > span {
  display: inline-block;
  vertical-align: middle;
}

.pagination li a, .pagination li.active .dataTables_paginate a {
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: $space-s 0 0 0;
  border: 1px solid $lightgrey;
  background: $lightestgrey;
  font-size: 0.8125em;
}

.pagination li a:focus, .pagination li a:hover, .dataTables_paginate a {
  background: $lightgrey;
}

.pagination li.active, .dataTables_paginate a.current {
  border: 1px solid color($primary-color);
  background: $lightblue;
}

.pagination li.active span, .dataTables_paginate a.current {
  color: color('white');
}

.pagination li, .dataTables_paginate a, .dataTables_paginate span span {
  margin-right: $space-xs;
}

.pagination li.pagination-first, .dataTables_paginate a.previous {
  float: left;
}

.pagination li.pagination-last, .dataTables_paginate a.next {
  margin-right: 0;
  float: right;
}

.pagination li.pagination-first span, .pagination li.pagination-last span {
  font-size: 0.6875em;
}

.pagination li.active span {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

/* DATATABLE PAGINATION ============================================================================= */
.dataTables_paginate a {
  padding: 10px;
  cursor: pointer;
}

.dataTables_paginate a.disabled {
  display: none;
}

@media screen and (max-width: 600px) {

  .pagination {
    //text-align: left;
  }

  .pagination li {
    margin-bottom: 20px;
    text-align: center;
    //float: left;
  }

  .pagination li.active span {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }

  .pagination li a {
    width: 27px;
    height: 27px;
    padding: 0;
    line-height: 27px;
  }

  .pagination li.pagination-last, .pagination li.pagination-first {
    float: left;
  }
}
