/* CONTACT ============================================================================= */
.ContactUsForm {
  margin-bottom: $space-xl !important;
}

.terms section li {
  margin: 0 0 0 50px;
  list-style: disc;
  list-style-position: outside;
}

.terms table td {
  padding: 10px;
  font-weight: 100;
}

.about section,
.team section {
  display: inline-block;
  width: calc(100% - 40px);
  margin: 20px 20px;
}

.full-banner.contact-header {
  @include cdn-background-image('/css/misc/contact-us.jpg');
  margin-bottom: 20px;
}

.center-bordered {
  padding: spacing();
  margin-bottom: spacing();
  font-weight: bold;
  text-align: center;

  *:first-child {
    margin-top: 0;
  }
}

.statics-table td, th {
    padding: 6px;
    border: 1px solid;
}
