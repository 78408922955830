.Bg-blue,

.c-primary {
  color: color('blue');
}

.c-red {
  color: color('red');
}

.color-primary {
  color: color('orange');
}