.ResponsiveVideo {
  margin-bottom: 1rem;
}

.ResponsiveVideo-container {
  position: relative;
  height: 0;
  padding-top: 25px;
  padding-bottom: 56.25%;
}

.ResponsiveVideo-iframe {
  @include position(absolute, $left: 0, $top: 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
