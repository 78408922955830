.Form-toggle {
  display: flex;
  input {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    opacity: 0;
    height: 1px;
    width: 1px;
  }
}
.Form-toggleBall {
  position: absolute;
  width: 19px;
  top: 3px;
  left: 3px;
  height: 19px;
  border-radius: 13px;
  background: #fff;
  transition: .3s;
}
.Form--toggleLabel {
  display: flex;
  align-items: center;
}
.Form-checkboxInput:checked + .Form-label,
.Form-toggle--checked {
  .Form-toggleBall {
    left: calc(100% - 23px);
  }
}
.Form-checkboxInput:checked + .Form-label,
.Form-toggle--checked {
  .Form-toggleContainer {
    background: #39B546;
  }
}
.Toggle-toggleText{
  display: inline-block;
}
.Form-toggleContainer {
  transition: .3s;
  transition: .3s cubic-bezier(0.0, 0.0, 0.2, 1);;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: color('grey', 'light');
  width: 40px;
  margin-right: .5rem;
  height: 25px;
  border-radius: 20px;
}
