// LEGACY Mixins
$font-size: 16 !default; // USED by admin
@mixin font-size($font-size) {
  font-size: #{$font-size}px;
}

@mixin headertext {
   font-family: $font-default;
  font-style: normal;
  font-weight: $font-weight-heavy;
}


@mixin b-shadow-hover($color:#cccccc, $background:#fff) {
  background: $background;
  z-index: 1;
  //box-shadow: 0 15px 20px rgba(0, 0, 0, .15);
  box-shadow: 0 30px 70px -32px #E78042;
  border-top: 1px solid $color;
  border-bottom: 1px solid $color;
}

@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  border-top-right-radius: $topright $topleft $bottomleft $bottomright;
}





