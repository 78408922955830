.Form-select,
select {
  @include input-default();
  @include input-error();
  @include input-focus();
  @include disabled();
  height: 48px;
  line-height: 46px;
  font-family: $font-default;
}

select::-ms-expand {
  display: none;
}

.select-field {
  position: relative;
}

.select-field:after {
  //content: '\e62a';
  //font-family: 'icomoon';
  @extend .far;
  position: absolute;
  right: 20px;
  color: $mediumgrey;
  line-height: 1;
  content: fa-content($fa-var-angle-down);
}
