@use 'sass:math';

$font-default: "proxima-nova",sans-serif;
//$f-script: "salsbury", 'Helvetica Neue', arial, sans-serif;
$font-weight-heavy: 700;
$fw-regular: 400;

$base-font-size: 112.5; // Is used as %
$line-height: 1.5; // = 27px

$bases: 16 * (math.div($base-font-size, 100));
$leading: $bases * $line-height;