/* HEADER ============================================================================= */
$headerBreak: 1250px;

.GlobalHeader {
  //border-top: 2px solid #ed923f;
  position: relative;
  background-color: #FFF;
  border-bottom: 1px solid color('grey', 'light');
  z-index: 6;
  position: fixed;
  //@include z-index(header);
  right: 0;
  left: 0;
  top: 0;
  height: 50px;
  //@include box-shadow(0px 0px 15px 0px rgba(0, 0, 0, 0.15));
  @include respond-to(sm) {
    position: relative;
    height: auto;
  }

  @include respond-to(sm, 'max-width') {
    .userDetails {
      padding: 0 !important;

      .name, .ProgressBar {
        display: none;
      }
    }
  }
}

.GlobalHeader-content {
  display: flex;
  //height: 60px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 0;
}

.Nav-hasSubLinks {
  display: none;
  @include customMediaQuery(min-width, $headerBreak) {
    display: block;
  }
}

.control {
  .GlobalHeader {
    margin-bottom: spacing(lg);
  }
}

.Nav-link,
.Nav-items,
.Nav {
  height: 100%;
}

.RmaLogo {
  @include customMediaQuery(max-width, $headerBreak) {
    flex-grow: 1;
  }
  @include customMediaQuery(min-width, $headerBreak) {
    margin-right: auto;
  }
}

.RmaLogo-link {
  text-indent: 100%;
  width: 185px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  @include cdn-background-image('/assets/img/homepage/rmalogo.svg');
  background-repeat: no-repeat;
  background-position: center left;
  max-width: 100%;
  background-size: 100% auto;
  min-height: 40px;
  margin: auto;
  padding: 0;
  @include customMediaQuery(min-width, 450px) {
    width: 250px;
  }
  @include respond-to(lg) {
    width: 320px;
  }
}

.Nav {
  float: left;
  margin-left: auto;
  @include customMediaQuery(max-width, $headerBreak) {
    position: fixed;
    overflow: scroll;
    padding: spacing();
    left: 0;
    background: #FFF;
    width: 100%;
    max-width: 500px;
    bottom: 0;
    top: 50px;
    height: calc(100% - 61px);
    z-index: 10;
    transform: translateX(-100%);
    .Nav-items {
      padding-bottom: $space-l;
    }
    &.is-opened {
      transition: transform .2s ease-out;
      transform: translateX(0);
    }
  }
}

.Overlay {
  @include customMediaQuery(max-width, $headerBreak) {
    background: rgba(231, 128, 66, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    z-index: 6;
    &.Menu {
      z-index: 1;
      top: 61px;
    }
    &.is-opened {
      display: block;
    }
  }
}

.MobileMenu-closeIcon {
  display: none;
}

@include customMediaQuery(max-width, $headerBreak) {
  .is-opened {
    .MobileMenu-openIcon {
      display: none;
    }

    .MenuOverlay {
      display: block;
    }

    .MobileMenu-closeIcon {
      display: block;
    }
  }
}

.Nav-item {
  float: left;
  width: 100%;
  margin-bottom: 0;
  @include customMediaQuery(min-width, $headerBreak) {
    position: relative;
    width: auto;
    &:hover {
      .Nav-itemSubNav {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.Nav-items {
  @include customMediaQuery(min-width, $headerBreak) {
    display: flex;
    flex-wrap: wrap;
    & > .Nav-item > .Nav-link {
      line-height: 60px;
      padding: 0 8px;
    }
  }
}

.Nav-itemSubNav {
  @include customMediaQuery(min-width, $headerBreak) {
    border-radius: 4px;
    margin-top: -1px;
    display: none;
    top: 100%;
    position: absolute;
    left: -#{spacing()};
    z-index: 4;
    background: color('grey', 'darkest');
    color: color('white');
    width: 320px;
    padding: spacing();
    &::before {
      border: 10px solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      left: spacing(lg);
      bottom: 100%;
      margin-bottom: 0;
      border-bottom-color: color('grey', 'darkest');
    }
    .Nav-item {
      display: block;
      width: 100%;
    }
    .Nav-link {
      color: color('white');

      &:hover {
        color: color('turquoise');
      }
    }
  }
}

.Nav-link {
  padding: 8px;
  color: color('grey', 'darkest');
  display: block;

  @include customMediaQuery(max-width, $headerBreak) {
    font-size: 20px;
    margin: 4px 0;
  }

  &.Nav--hasSubLinks {
    display: none;
    @include respond-to(md) {
      display: block;
    }
  }
}

.Auth {
  margin-left: 8px;
  float: right;
  display: flex;

  .LoggedOutLoadingSlot {
    width: 212.3px;
    height: 75px
  }

  .LoggedInLoadingSlot {
    width: auto;
    padding-left: 90px;
    height: 75px
  }

  @media (max-width: 1090px) {
    .LoggedOutLoadingSlot {
      width: 100.3px;
      height: 75px
    }
    .LoggedInLoadingSlot {
      width: auto;
      padding-left: 90px;
      height: 75px
    }
  }

  .Button {
    //margin-right: 15px;
  }

  @include customMediaQuery(max-width, 1250px) {
    margin-left: auto;
  }
}

#menu-toggle {
  float: left;
  position: relative;
  top: 9px;
  display: none;

  span {
    font-size: 26px;
    color: color('grey');
    cursor: pointer;
  }
}

.Auth-item {
  position: relative;
  display: inline-block;
  margin-bottom: 0;

  &:hover {
    .Auth-subMenu {
      display: block;
    }
  }
}

.Auth-logedIn > .Auth-item > .Auth-link {
  line-height: 60px;
  height: 60px;
  min-width: 40px;
  text-align: center;
  @include customMediaQuery(min-width, $headerBreak) {
    text-align: left;
  }
}

.Auth-link {
  display: block;

  &:hover {
    color: color('turquoise');
  }
}

.Auth--login {
  display: block;
  color: color('orange');
  @include customMediaQuery(min-width, $headerBreak) {
    padding-right: 8px;
  }
}

.Auth-subMenu {
  margin-top: -1px;
  display: none;
  top: 100%;
  //  background: color($primary-color);
  background: color('grey', 'darkest');
  //color: #fff;
  right: -15px;
  border-radius: 4px;
  position: absolute;
  padding: spacing();
  width: 150px;

  &::before {
    border: 10px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    right: spacing(lg);
    bottom: 100%;
    margin-bottom: 0;
    border-bottom-color: color('grey', 'darkest');
  }

  span {
    color: color('turquoise');
  }

  .Auth-link {
    padding: spacing(xs) 0;
    color: color('white');

    &:hover {
      color: color('turquoise');
    }
  }
}

.Auth-name {
  display: none;
  // Unique media query due to the nav struction
  @include respond-to(sm) {
    display: inline;
  }
}

.Auth-logInIcon {
  display: block;
  line-height: 60px;
  @include customMediaQuery(min-width, $headerBreak) {
    display: none;
  }
}

.Auth-logInText,
.Auth-register {
  display: none;
  @include customMediaQuery(min-width, $headerBreak) {
    display: inline-block;
  }
}

.MobileMenu {
  top: 2px;
  width: 40px;
  text-align: center;
  position: relative;
  @include customMediaQuery(min-width, $headerBreak) {
    display: none;
  }
}

// Header overrides for notification port
.Auth {
  margin-left: 0;
}

.AccountNav-auth {
  display: flex;
  align-items: center;

  .AccountNav-login {
    margin-right: 10px;

    @include respond-to(xs, 'max-width') {
      margin: 0;
      .Button {
        padding: 0 4px;
      }
    }
  }
}

.HeaderCount-container {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  @include respond-to(xs, 'max-width') {
    padding-right: 8px;
  }
  .AccountNav-login,
  .signup {
    margin-bottom: 0;
  }

  > li {
    position: relative;
    margin: 0;
  }

  &.is-notLoggedIn {
    @include respond-to(xs, 'max-width') {
      .Notification::before {
        right: 69px;
      }
    }
  }

  #Notification {
    // right: -5px;
    top: 70px;

    @include respond-to(xs) {
      right: -50px;
    }

  }


  .Notification-triggerIcon {

    @include respond-to(xs) {
      margin-right: 8px;
    }

  }
}

.subnav.UserLoggedIn {
  display: flex;
  align-items: center;
  color: color($primary-color);

  .Icon.Icon-user-circle {
    font-size: 1.3rem;
    margin-right: 6px;
    padding: 22px 0;
  }

  .userDetails {
    display: inline-block;
    padding: 19px 0 11px 0;
    line-height: 19px;

    .name {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .fa-angle-down {
      vertical-align: middle;
    }
  }

  ul {
    //background-color: white;
    width: 160px;
    right: 0;
    left: auto;
    width: 160px;

    a {
      //display: block;
      //padding: 10px;
      //font-size: 13px;
      //font-weight: 700;
      //text-transform: uppercase;
    }
  }
}

header#pageheader nav.main-nav ul {
  position: relative;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
  float: left;
}

.subnav {
  position: relative;


  ul {
    display: block;
    position: absolute;
    background-color: #333;
    top: 64px;
    left: 0;
    width: 200px;
    border-radius: 8px;
    @include z-index(overlay);
    @include customMediaQuery(max-width, $headerBreak) {
      top: 44px;
    }

    &::before {
      left: auto;
      right: 2rem;
    }
  }

  .Nav-link {
    color: color('white');
  }
}


/* MOBILE NAVIGATION ============================================================================= */

.mobile-nav {
  display: none;
}

.mobile-nav .UserIcon {
  color: color($primary-color);
  font-size: 1em;
}

.mobile-menu {
  display: none;
  margin-right: $space-default;
  padding: 25px 11px 20px;
  float: left;

  span {
    color: color('white');
    font-size: 18px;
  }
}

@media (max-width: 1090px) {

  //.GlobalHeader {
  //  position: fixed
  //}

  #vue-header {
    li.signup {
      display: none;
    }
  }
  .mobile-menu,
  .mobile-nav {
    display: block;
    min-width: 0;
  }

}

/* Breadcrumbs ============================================================================= */
//Core-pages temporary css

#breadcrumbs {
  margin: $space-default $space-default 0 0;
}

#breadcrumbs li {
  display: inline-block;
  padding-right: 8px;
  color: $breadcrumb;
}

#breadcrumbs li:after {
  padding-left: 6px;
  content: '\003e';
}

#breadcrumbs li:last-child:after {
  content: '';
}

#breadcrumbs li a {
  border-bottom: 1px dotted $breadcrumb;
  color: $breadcrumb;
}

.MenuOverlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include z-index(behind);
  background: transparent;
}


#homepage-banner {
  overflow: hidden;
  background-color: #f96625;

  .header-title {
    font-family: proxima-nova, sans-serif;
    color:white;
    font-size: 38px;
    line-height: 38px;
    margin: 10px 5px 10px 0;
    font-weight: 900;
  }

  .header-tagline {
    font-size: 22px;
    color: white;
    margin: 2rem 0 3rem 0;
  }

  strong {
    color: #712d87;
    font-weight: 700;
  }

  .Button--purple, .Button--orange {
    height: 34px;
    line-height: 34px;
    margin-bottom: 1rem !important;
  }

  .Button {
    margin: 0;
    font-size: 1.3rem;
  }

  .Button--purple {
    margin-right: 1rem;
  }

  .lg-screen {
    display: none;
  }

  .header-text {
    padding: 2rem 1.5rem;
  }

  .sm-screen {
    max-width: 254px;
    min-width: 200px;
    width: 100%;
  }

  @media screen and (min-width: 698px) {

     .tablet-container {
       display: block;
     }

    .desktop-image-container {
      align-self: end;
    }

      .header-title {
        font-size: 54px;
        line-height: 54px;
      }
      .header-tagline {
        font-size: 24px !important;
        color: white;
        margin: 2rem 0 3rem 0;
      }
      .sm-screen {
        display: none;
      }
      .lg-screen {
        display: block;
        min-width: 480px;
      }
  }

  @media screen and (max-width: 907px) and  (min-width: 699px) {
    .header-title {
      font-size: 40px;
      line-height: 40px;
    }
  }

  @media screen and (min-width: 538px) {
    .tablet-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: end;
    }
  }

  // Smaller mobile screens
  @media screen and (max-width: 376px) {
    .header-title {
      font-size: 27px;
      line-height: 27px;
    }
    .Button {
      font-size: 1.1rem;
    }
  }
  }

//  @media screen and (max-width: 768px) {
//    h1.xl-header {
//      font-size: 2.2rem;
//      line-height: 1.2;
//    }
//    .CtaBanner-text {
//      margin: 1rem 0;
//      max-width: 430px;
//    }
//  }
//
//  // Tablet
//  @media screen and (max-width: 699px) {
//    h1.xl-header {
//      font-size: 1.5rem;
//      line-height: 1.2;
//      white-space: normal;
//    }
//
//    .Mobile-container {
//      display: flex;
//      margin-top: 1rem;
//      align-items: center;
//    }
//
//    .Container {
//      padding: 0 1rem;
//    }
//
//    .CtaBanner-text {
//      margin: 1rem 0;
//      font-size: 16px;
//    }
//  }
//
// span.NAW-bg {
//   background-size: cover;
//   display: block;
// }
//
//  .Container {
//    padding: 0 0 0 1rem;
//  }
//
//  .NAW-desktop {
//    display: flex;
//    align-items: end;
//    margin-right: -46px;
//    @media screen and (max-width: 426px) {
//      margin-right: 0;
//    }
//
//
//    img {
//
//      @media screen and (max-width: 698px) {
//        display: none;
//      }
//      @media screen and (max-width: 768px) {
//        max-width: 317px;
//        width: 100%;
//        min-width: 290px;
//      }
//
//      max-width: 383px;
//    }
//  }
//
//  img.NAW-mobile {
//    display: none;
//
//    @media screen and (max-width: 698px) {
//      display: inline-flex;
//      max-width: 30%;
//      width: 100%;
//      margin-left: 5px;
//    }
//  }
//
//  .CtaBanner-copy {
//    margin: 20px 0;
//  }
//
//  .CtaBanner-text {
//    max-width: 662px;
//    margin: 2rem 0;
//    font-size: 18px;
//  }
//
//  .Button--purple {
//    background-color: #712d87;
//    border-radius: 12px;
//    margin: 1.5rem 1rem 1.5rem 0;
//  }
//
//  .xl-header {
//    font-weight: 900;
//    line-height: 1;
//    margin: 0;
//    font-size: 3.4rem;
//  }
//}
//
//#NAW-banner {
//  overflow: hidden;
//  background-color: #f96625;
//
//  strong {
//    font-weight: 700;
//  }
//
//  .Button--purple {
//    height: 34px;
//    line-height: 34px;
//  }
//
//  @media screen and (max-width: 768px) {
//    h1.xl-header {
//      font-size: 2.2rem;
//      line-height: 1.2;
//    }
//    .CtaBanner-text {
//      margin: 1rem 0;
//      max-width: 430px;
//    }
//  }
//
//// TABLET
//  @media screen and (max-width: 560px) {
//    h1.xl-header {
//      font-size: 1.5rem;
//      line-height: 1.2;
//      white-space: normal;
//    }
//
//    .Mobile-container {
//      display: flex;
//      margin-top: 1rem;
//      align-items: center;
//
//      @media screen and (max-width: 390px) {
//        flex-wrap: wrap;
//      }
//    }
//
//    .Container {
//      padding: 0 1rem;
//    }
//
//    .CtaBanner-text {
//      margin: 1rem 0;
//      font-size: 16px;
//    }
//  }
//
//  span.NAW-bg {
//    background: #EA5B1B;
//    background-size: cover;
//    display: block;
//  }
//
//  .Container {
//    padding: 0 0 0 1rem;
//  }
//
//  .NAW-desktop {
//    display: flex;
//    align-items: end;
//    margin-right: -46px;
//    @media screen and (max-width: 426px) {
//      margin-right: 0;
//    }
//
//
//    img {
//
//      @media screen and (max-width: 426px) {
//        display: none;
//      }
//      @media screen and (max-width: 768px) {
//        max-width: 317px;
//        width: 100%;
//        min-width: 290px;
//      }
//
//      max-width: 383px;
//    }
//  }
//
//  img.NAW-mobile {
//    display: none;
//    order: 1;
//
//    @media screen and (max-width: 420px) {
//      display: inline-flex;
//      max-width: 110px;
//      width: 100%;
//      margin-left: 5px;
//      order: 0;
//
//    }
//  }
//
//  .CtaBanner-copy {
//    margin: 20px 0;
//  }
//
//  .CtaBanner-text {
//    max-width: 500px;
//    margin: 2rem 0;
//    font-size: 18px;
//
//    strong {
//      color: #712D87;
//    }
//  }
//
//  .Button--orange {
//    background-color: color('orange');
//    border-radius: 12px;
//    margin: 0;
//  }
//
//
//  .xl-header {
//    font-weight: 900;
//    line-height: 1;
//    margin: 0;
//    font-size: 3.4rem;
//  }
//}




// RMA top banner css
// .NavNotice--info {
//   text-align: center;
//   background-color: #712d87;
//   font-size: 1rem;
//   font-weight: bold;
//   padding: 10px;
//   color: color('white');
//   z-index: 4;
//   width: 100%;
//
//   @include respond-to('md', 'max-width') {
//     &:not(.Mobile) {
//       display: none;
//     }
//   }
// }
//
//
// .NavNotice--info.SearchSuggestions-header {
//   margin-bottom: 5px;
//   border-radius: 6px;
//
//   .Button {
//     margin: 5px 0;
//   }
// }

