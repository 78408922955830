$max-width: 1344px;

$retina: 'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)';

/*------------------------------------*\
    GRID FUNCTION
\*------------------------------------*/

/**
 * Space between columns
 */
/// Gutter
$grid-gutter: 24px !default;

/**
 * Number of total columns
 */

$grid-columns: 12 !default;


/// Responsive Media Query
/// @example
///   @include respond-to(sm, min-width){
///     content here
///   }
/// @param {type} $breakpoint [none] - `xs`, `sm`, `md`, `lg`
/// @param {type} $direction [`min-width`] -  Responsive Direction (`min-width` or `max-width`)
@mixin respond-to($breakpoint, $direction: min-width) {
  @if not map-has-key($breakpoints, $breakpoint) {
    @throw 'Invalid breakpoint `#{$breakpoint}`. Choose one of `#{map-keys($breakpoints)}`.';
  }

  @media ($direction: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}

/// @example
///   @include customMediaQuery(max-width: 1250px){
///     content here
///   }
/// @param {type} $direction [none] - Responsive Direction (`min-width or max-width`)
/// @param {type} $screen [none] - Screen Size e.g (768px or 64em)
@mixin customMediaQuery($direction, $screen) {
  @media only screen and ($direction: $screen) {
    @content;
  }
}
