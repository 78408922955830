/** @define Recruiters */
.Recruiters {
  display: block;
  position: relative;
  background: color('turquoise');
  border-radius: radius();
  margin:  spacing() 0 0;
  padding: spacing(xs)  spacing();
  transition: box-shadow .2s;
  border: 1px solid transparent;

  &:hover {
    box-shadow: 0 30px 70px -24px color('orange');
    background: color('turquoise');
  }

  &:active {
    transform: translateY(3px);
  }

  &::after {
    //@include fa-icon;
    @extend .far;
    content: fa-content($fa-var-chevron-right);
    @include valign(relative);
    position: absolute;
    right:  spacing();
    color: color('white');
  }
}

.Recruiters-title {
  margin-top: 0;
  color: color('white');
  margin-bottom: 3px;
  font-size: 18px;
}

.Recruiters-tagLine {
  margin: 0;
font-size: 13px;
}
