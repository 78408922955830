@use 'sass:math';
/// Grid loop
///
/// used best in a loop, calculates the width for a grid system based on grid columns
/// returns - column class names, widths and offset classes
///
/// @example
///    @include grid-loop($name);
/// @param {type} $name [none]



@mixin grid-loop($name) {
  @for $i from 0 through $grid-columns {
    @if $i != 0 {
      .Col-#{$name}-#{$i} {
        width: percentage(math.div($i, $grid-columns))
      }
    }

    .Col-#{$name}-offset-#{$i} {
      margin-left: percentage(math.div($i, $grid-columns));
    }
  }
}