.field .radio input[type='radio'], // Legacy Support
.Form-field--radio .Form-input {
  @include radio-check-input();
}

.field .radio label:before, //Legacy
.Form-field--radio .Form-label:before {
  @include radio-check-pseudo();
  border-radius: 20px;
  margin-right: spacing(sm);
  margin-top: 2px;
  float: left;
  border: 6px solid transparent;
  content: '';
}

.Form-field--radio .Form-input:checked + .Form-label:before,
.field .radio input[type='radio']:checked + label:before {
  @include checked('radio');
}

.Form-field--radioButton {
  display: inline-block;
  text-align: center;

  .Form-label {
    width: 100%;
    border: 2px solid color('grey');
    padding: spacing(sm) spacing();
    border-radius: radius(sm);
    color: color('grey', 'darkest');

    &:before {
      display: none;
    }
  }
}

.Form-field--radioButton .Form-input:checked + .Form-label {
  color: color($primary);
  border-color: color($primary);
}

.Form-field--radioGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .Form-field--radioButton {
    flex-grow: 1;
    margin-right: spacing();
    flex-basis: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}
