/* RESET ============================================================================= */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: color($primary);
  &:hover,
  &:focus {
    color: $mediumgreen;
  }
}

ol, ul {
  list-style: none;
  list-style-position: outside;
  margin-bottom:1rem;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

//html{overflow-y:scroll; height:100%; min-height:100%;}

audio[controls], canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/* Typography ========================================================================== */

abbr[title] {
  border-bottom: 0;
  text-decoration: none;
}

b, strong {
  font-weight: bold;
}

blockquote {
  margin: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  color: #000;
  font-weight: bold;
}

/* Redeclare monospace font family: en.wikipedia.org/wiki/User:Davidgothberg/Test59 */
pre, code, kbd, samp {
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

/* Improve readability of pre-formatted text in all browsers */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before, q:after {
  content: '';
  content: none;
}

small {
  font-size: 85%;
}

/* Position subscript and superscript content without affecting line-height: gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/*  Lists  ========================================================================== */

dd {
  margin: 0;
}

nav ul, nav ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*  Figures  ========================================================================== */

figure {
  margin: 0;
}

/* Forms  ========================================================================== */

label {
  cursor: pointer;
}

button, input, select, textarea {
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input[type='button'], input[type='reset'], input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type='checkbox'], input[type='radio'] {
  box-sizing: border-box;
}

input[type='search'] {
  box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

/* apply a natural box layout model to all elements */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.page-wrapper {
  position: relative;
  //min-height: 100%;
  //margin-bottom: $space-xl;
  @include clearfix;
}

main {
  display: block;
  margin-bottom: $space-xl * 2;
  @include clearfix;
  &.HomePageTwo {
    margin-bottom: 0;
  }
}


#pageheader {
  .content {
    &:after, &:before {
      content: '';
      clear: both;
      display: table;
      width: 100%;
    }
  }
}


html,body{
  width: 100%;
  max-width: 100%;
}
