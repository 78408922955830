/// Arrow
/// uses @include position(absolute) mixin
/// @example
///   @include center('both');
/// @param {type} $position [none] - `both`, `horizontal`, `vertical`
@mixin arrow($position: left, $size: 5px, $color: inherit) {
  width: 0;
  height: 0;
  @if $position == 'top' {
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  } @else if $position == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $position == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $position == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  }
}
