/*== Form Validation Error ========*/
.error-message {
  width: 100%;
  padding: 8px 15px;
  float: left;
  color: color('white');

  p {
    margin: 0;
    color: color('white');
  }
}

textarea.error,
input.error {
  background: none;
  color: #434a4f !important;
}

.Validation {
  padding-top: spacing(xs);
  font-size: rem(14px);
}

.Validation--error {
  color: color('red');
}

.Form-field,
.field {
  &.error {
    input, select, textarea {
      border: 1px solid $mediumred !important;
    }
  }

  &.success {
    input, select, textarea {
      border: 1px solid $mediumgreen !important;
    }
  }
}

.Validation--error,
.validation-error, .error-message {
  p {
    color: $white !important;
  }

  position: relative;
  margin-top: 6px;
  padding: 8px 15px;
  clear: both;
  border-radius: 4px;
  background: $lightred;
  color: $white !important;

  &:before {
    margin-bottom: -1px;
    content: '';
    @include position(absolute, $left: 20px, $bottom: 100%);
    @include arrow(top, $size: 5px, $color: color('red'));
  }
}
