/** @define Section */
.Entry {
  .Section {
    @include respond-to(sm) {
      max-width: 800px;
      margin: spacing(xl) auto 0 auto
    }
  }
}
.Section {
  margin-top: spacing();
  @include respond-to(sm) {
    margin-top: spacing(lg)
  }
}
