/* Articles Styling ============================================================================= */

.gbg {
  background: #f0ede9;
  padding: spacing();
  margin-bottom: spacing();

  *:first-child {
    margin-top: 0;
  }
}

.center-bordered {
  padding: spacing();
  margin-bottom: spacing();
  font-weight: bold;
  text-align: center;

  *:first-child {
    margin-top: 0;
  }
}

.uni-table td, th {
    padding: 6px;
    border: 1px solid;
}

article {
  position: relative;
}

.AboutUser {
  display: flex;
  @include respond-to(sm, 'max-width') {
    flex-wrap: wrap;
  }

  .Card {
    margin-right: spacing();

    &:last-child {
      margin-right: 0;
    }

    @include respond-to(sm, 'max-width') {
      width: 100%;
      margin-right: 0;
      margin-bottom: spacing();
    }
  }
}

blockquote {
  color: color('purple');
  font-style: italic;
  font-size: 18px;
  font-weight: $fw-regular;
  text-transform: none;
  position: relative;
  margin: spacing() 0;

  cite {
    display: block;
    width: 100%;
    font-style: normal;
  }

  p {
    color: inherit;
  }

  em {
    display: block;
    color: color('grey');
    font-size: 14px;
    font-style: normal;
    font-weight: $fw-regular;
  }
}

// global styling of article elements is a recipe for disaster.
table {
  font-size: rem(16px);
  margin-bottom: spacing();
}


.ArticleContent {
  padding: 0 1rem;
  max-width: 750px;
  margin: auto;

  .HotJobs {
    margin-bottom: spacing(lg);
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .gbg img {
    width: 100%;
    margin-left: 0;
    max-width: 100%;
  }
}

.HeaderImage {
  //border: 1px solid $lightgrey;
  border-bottom: none;

  img {
    width: 100%;
  }
}


.main-content.page {
  figure {
    max-height: 400px;
    position: relative;
    margin-bottom: -80px;

    img {
      width: 100%;
    }
  }

  ul.inline {
    &::before,
    &::after {
      display: block;
      width: 100%;
      clear: both;
    }

    li {
      float: left;
      margin-right: 1%;
      width: 48%;
    }
  }

  article {
    position: right;
    width: 80%;
    margin: auto;
    padding: 0 5% 5%;
    //        img {
    //            display: none;
    //        }
    .social-bar {
      em {
        //            font-family: salsbury;
        color: color('orange');
        display: block;
        text-align: center;
        font-weight: $fw-regular;
        font-size: 18px;
      }
    }

    hr {
      width: 100%;
    }

    .wrap {
      margin: 0 0 0 -15px;
    }

    .gbg {
      background: color('grey', 'light');
      padding: 2%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

//.ResponsiveVideo-container {
//  position: relative;
//  padding-bottom: 56.25%; /* 16:9 */
//  padding-top: 25px;
//  height: 0;
//}
//
//.ResponsiveVideo-iframe {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//}
.ArticleContent,
.Article {
  background: #FFF;
  position: relative;
  ul{
    @extend .List--bullet !optional;
  }
  ol{
    @extend .List--numeric !optional;
  }
}

.StaticHeader {
  font-size: 20px;
  margin: spacing(lg) 0 0 0;
  color: #000000;
  border-bottom: 1px solid color('grey', 'light');
  padding-bottom: spacing();
}

.list-menu {
  margin-top: $space-l;

  .item {
    min-height: 100%;
  }
}
