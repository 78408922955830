// ------------- loading spinner -------------

$loadingSpinnerDiameter: 28px;
$loadingSpinnerStrokeWidth: 4px;
$loadingSpinnerStrokeColor: inherit;
$loadingSpinnerOpacity: .8;
$loadingSpinnerStrokeStyle: solid;
$loadingSpinnerSpeed: .7s;

.Button {
  height: 48px;
  min-width: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: radius(md);
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  letter-spacing: -.12px;
  font-weight: $font-weight-heavy;
  //display: inline-block;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  text-transform: uppercase;
}

.Button--long {
  width: 100%;
}

.Button--primary
  //.Button--primary
{
  background: $primary-button-background;
  color: $primary-button-text-color;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);
  &:before {
    border-color: $subtle-button-text-color;
  }

  &:focus,
  &:hover {
    background: $primary-button-background-hover;
    color: $primary-button-text-color-hover;
    //box-shadow: 0 0 0 2px lighten(color($primary-button-background), 10%);
  }
}

.Button--primary, //TEst
.Button--orange {
  background: color('orange');
  color: $primary-button-text-color;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);
  &:before {
    border-color: $subtle-button-text-color;
  }

  &:focus,
  &:hover {
    background: lighten(color('orange'), 5%);
    color: $primary-button-text-color;
    //box-shadow: 0 0 0 2px lighten( color('orange'), 10%);
  }
}

.Button--danger {
  background: $danger-button-background;
  color: $danger-button-text-color;

  &:before {
    border-color: $subtle-button-text-color;
  }

  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);
  &:focus,
  &:hover {
    background: $danger-button-background-hover;
    color: $danger-button-text-color;
    //box-shadow: 0 0 0 2px lighten(color($danger-button-background), 10%);
  }
}

.Button--purple {
  background: color('purple');
  color: $danger-button-text-color;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);
  &:before {
    border-color: $subtle-button-text-color;
  }

  &:focus,
  &:hover {
    background: lighten(color('purple'), 5%);
    color: $danger-button-text-color;
    //box-shadow: 0 0 0 2px lighten(color('purple'), 10%);
  }
}
.Button--green {// Todo satisfy dom - to remove
  background: color('green');
  color: $danger-button-text-color;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);
  &:before {
    border-color: $subtle-button-text-color;
  }

  &:focus,
  &:hover {
    background: lighten(color('green'), 5%);
    color: $danger-button-text-color;
    //box-shadow: 0 0 0 2px lighten(color('purple'), 10%);
  }
}

.Button--secondary {
  background: $secondary-button-background;
  color: $secondary-button-text-color;

  &:before {
    border-color: $subtle-button-text-color;
  }

  &:focus,
  &:hover {
    background: $secondary-button-background-hover;
    //color: $secondary-button-text-color-hover;
    //box-shadow: 0 0 0 2px lighten(color($secondary-button-background), 10%);
  }
}

.Button--tertiary {
  background: $tertiary-button-background;
  border: 1px solid $tertiary-button-border-colour;
  color: $tertiary-button-text-color;
  line-height: 46px;

  &:before {
    border-color: $subtle-button-text-color;
  }

  &:focus,
  &:hover {
    background: $tertiary-button-background-hover;
    color: $tertiary-button-hover-text-color;
    //box-shadow: 0 0 0 2px lighten(color($tertiary-button-background), 10%);
  }
}

.Button--grey,
.Button--subtle {
  background: $subtle-button-background;
  color: $subtle-button-text-color;
  line-height: 48px;

  &:before {
    border-color: $subtle-button-text-color;
  }

  &:focus,
  &:hover {
    background: $subtle-button-background-hover;
  }
}

.Button--disabled {
  background: $subtle-button-background;
  color: color('grey');
  cursor: auto;

  &:focus,
  &:hover {
    background: $subtle-button-background;
    color: color('grey');
  }
}

.Button-iconLeft {
  font-size: 24px;
  margin-right: 8px;
}

.Button-iconRight {
  font-size: 24px;
  margin-left: 8px;
}

.Button-icon {
  font-size: 24px;
}


.Button + .Button {
  margin-left: 16px;
}

.Button--iconOnly {
  .Shortlist-label {
    display: none;
  }
}

.text-link {
  text-decoration: none;
  color: $text-link;

  &::after {
    position: relative;
    @extend .fas;
    font-weight: 400;
    top: 1px;
    left: 10px;
    content: "\F061";
    transition: left .2s ease-out;
  }

  &:hover {
    &::after {
      left: 15px;
    }
  }
}

.Button.text-link::after {
  content: '';
}

//Over-ride
header#pageheader .AccountNav li.AccountNav-login a {
  border-radius: 8px;
}

.Button--small {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;

  span {
    font-size: 16px;
  }
}

.Buttons {
  text-align: right;
  display: flex;
}


.spinner, //legacy delete when no longer referenced
.loading {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: inherit;
    height: 100%;
    border-radius: inherit;
  }

}

.is-loading::before,
.spinner::before, //legacy delete when no longer referenced
.loading::before {
  content: '';
  border: $loadingSpinnerStrokeWidth $loadingSpinnerStrokeStyle;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  opacity: $loadingSpinnerOpacity;
  border-radius: 50%;
  animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
  margin-top: -10px;
  margin-left: -10px;
  position: absolute;
  top: 50%;
  z-index: 2;
  left: 50%;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(360deg)
  }
}


//Move
.m-fullWidth {
  width: 100%;
  @include respond-to(xs) {
    width: auto;
  }
}
