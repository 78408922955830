.RMP-Post--Author {
  background-color: #F5F5F5;
  padding: 16px;
  display: flex;
  gap: 16px;

  .is-size-4 {
    margin-top: 0;
  }

  .media-left {
    flex-shrink: 0
  }
}