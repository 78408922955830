 .HotJob {
  display: flex;
  flex-wrap: wrap;
  @include border-radius();
  //border:1px solid #DFDFDF;
  @include respond-to(xs) {
    margin-top: 1px;
    margin-left: 1px;
  }
}
 .HotJob-TagWrapper {
   display: flex;
 }
.HotJob-job {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: $space-s;
  //margin: -1px 0 0;
  float: left;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  //border: none;
  //box-shadow: 0 1px 1px 0 rgba(10, 16, 34, .2);
  //border: 1px solid rgba(10, 16, 34, .05);
  //background: #FFFFFF;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);
  overflow: hidden;
  &.is-featured::before {
    display: block;
    position: absolute;
    top: -10px;
    left: 10px;
    width: 0;
    height: 0;
    transform: rotate(-90deg);
    border-top: 20px solid #ff9b32;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
    content: '';
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }

  /*  &:focus,
    &:hover {
      z-index: 1;
      @include dropShadow;
    }
    */
  @include respond-to(xs) {
    overflow: visible;
    &:nth-child(1) {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
      overflow: hidden;
    }

    &:nth-child(10) {
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
    }

    //border: 1px solid $borderColor;
  }

  @include respond-to(sm) {
    margin-bottom: 4px;
    &:nth-child(2n + 2) {
      margin-left: 4px;
    }

    &:nth-child(1) {
      border-top-left-radius: $radius;
      border-top-right-radius: 0;
      overflow: hidden;
    }

    &:nth-child(2) {
      border-top-right-radius: $radius;
    }

    &:nth-child(9) {
      border-bottom-left-radius: $radius;
    }

    &:nth-child(10) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: $radius;
    }
  }

  @include respond-to(sm) {
    width: calc(50% - 4px);
    transition: .3s ease;
    //margin-left: -1px;
    //margin-top: -1px;
    &.is-featured::before {
      top: -13px;
      left: 12px;
      border-top: 25px solid #ff9b32;
      border-bottom: 25px solid transparent;
      border-left: 25px solid transparent;
    }
  }
  .Shortlist-label{
    display: none;
  }
}

 .HotJob-job .ShortlistIcon {
   align-self: center;
   margin-top: 10px \9;
   margin-left: auto;
 }

.HotJob-specs {
  float: left;
  text-align: left;
}

.HotJob-link {
  display: flex;
  width: calc(100% - 75px);
  padding: $space-default 0 $space-default $space-default;
  float: left;
  /*  &:focus,
    &:hover {
      .Job-title,
      .companyName {
        color: color($primary);
      }
    }*/
}

.HotJob-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1em;
  text-align: left;
  font-weight: bold;
}

.companyName {
  display: block;
}

.Logo {
  display: none;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border: 1px solid $borderColor;
  @include respond-to(sm) {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: $space-default;
    float: left;
  }
}

 .HotJob-heart{
   align-self: center;
   margin-right: 1.6rem;
   margin-left: auto;
 }
