@mixin translateY($transformX: 0) {
  transform: translate($transformX);
}

@mixin transform($transform) {
  transform: $transform;
}

@mixin translateX($transformX: 400px) {
  transform: translate($transformX);
}

@mixin translate($transformX : 20px, $transformY : 20px) {
  transform: translate($transformX, $transformY);
}
