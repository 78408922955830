h1,h2,h3,h4,
.h1,
.h2,
.h3,
.h4 {
  font-family:  $font-default;
  color: #000000;
  font-weight: $font-weight-heavy;
  font-style: normal;
}

//.industry,
//.duration-zone {
//  article {
//    h1, h2, h3, h4,
//    .h1,
//    .h2,
//    .h3,
//    .h4 {
//      margin: 1.5em 0px 0.8em;
//    }
//  }
//}

.xl-header{
  @include respond-to('md') {
    font-size: 2.5rem;
    line-height: 1.5;
  }
}

h1,
.h1 {
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 24px;
}
h2,
.h2 {
  font-size: 24px;
  letter-spacing: -0.16px;
  line-height: 40px;
  margin-bottom: 24px;
}
h3,
.h3 {
  font-size: 20px;
  letter-spacing: -0.16px;
  line-height: 30px;
  margin-bottom: 16px;
}
h4,
.h4 {
  font-size: 18px;
  letter-spacing: 0.22px;
  line-height: 24px;
  margin-bottom: 8px;
}

p {
  font-size: 16px;
  color: #222222;
  letter-spacing: 0.23px;
  line-height: 24px;
  margin-bottom: 24px;
}

//list
ul{

}
li{
  margin-bottom: spacing(sm);
  //padding-left: 16px;
  position: relative;
  //display: flex;
}
.SubHeader-small{
  font-size: 16px;
  color: #222222;
  letter-spacing: 0.23px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: spacing(sm);
}
.TitleHeader {
  margin-top: 0;
  margin-bottom: 0;
  @include respond-to(md, max-width) {
    font-size: 24px;
    letter-spacing: -0.16px;
    line-height: 1.2;
  }
}

small, .font_small {
  font-size: 0.75em;
}

::-moz-selection {
  background: $darkgrey;
  color: color('white');
}

::selection {
  background: $darkgrey;
  color: color('white');
}
