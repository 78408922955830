html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-family:  $font-default;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block
}

body,
button,
input,
select,
textarea {
  font-family:  $font-default;
}


body {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

// Inline

a {
  color: color($primary-color);
  cursor: pointer;
  text-decoration: none
}


hr {
  background-color: #DDDDDD;
  border: none;
  display: block;
  height: 1px;
  margin-top: 64px;
  margin-bottom: 64px;
  &.medium{
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

img {
  height: auto;
  max-width: 100%
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline
}

small {
  font-size: 13px;
}

span {
  font-style: inherit;
  font-weight: inherit
}

strong {
  //color: #000;
  font-weight:  600;
}

// Block

fieldset {
  border: none
}

table,
td,
th {
  vertical-align: top;

  &:not([align]) {
    text-align: inherit
  }
}

#breadcrumbs li:after {
  content: '/'
}



.color-primary {
  color: color('orange');
}
.spacer-md{
  height: 16px;
  width: 16px;
  display: block;
}

.PageHeader{
  margin-top: spacing();
}