.Has-icon {
  position: relative;
  width: 100%;
  &::before {
    position: absolute;
    color: color('grey');
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  }
  // defualt
  .Form-input,
  .Form-select,
  .Form-textArea{
    padding-left: 42px;
  }

  &.Icon-right {
    &::before {
      right: 16px;
      left: auto;
    }

    .Form-input,
    .Form-select,
    .Form-textArea{
      padding-right: spacing(lg) ;
      padding-left: spacing();
    }
  }

  &.Icon-left {
    &::before {
      left: 16px;
    }

    .Form-input,
    .Form-select,
    .Form-textArea{
      padding-left: spacing(lg) ;
    }
  }
  &.Form--error{
    &::before {
      color: color('red');
    }
  }
  &.Form--valid{
    &::before {
      color: color('green');
    }
  }
}

