/// border radius
$border-radius-data: (
        base: 3px,
        large: 6px,
        xl: 8px,
);

/// border radius NEW
$radius-data: (
        sm: 4px,
        md: 8px,
        lg: 16px,
);

@function radius($radius: md) {
  @return map-get($radius-data, $radius);
}

// old
$radius: 6px;
@mixin border-radius($radius) {
  border-radius: $radius;
}


