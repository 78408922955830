@import 'form-components/form-mixins';
@import 'form-components/input-has-icon';
@import 'form-components/labels';
@import 'form-components/input';
@import 'form-components/select';
@import 'form-components/textarea';
@import 'form-components/form-fields';
@import 'form-components/form-group';
@import 'form-components/radio';
@import 'form-components/checkbox';
@import 'form-components/toggle';
@import 'form-components/option-picker';
@import 'form-components/form-errors';
@import 'form-components/hint';
@import 'form-components/select2';
@import 'form-components/multiselector';
@import 'form-components/autocomplete';
@import 'form-components/auto-suggest';
//@import '_rma-form';

/*
$styleguide

$title Forms

<div class='field'>
  <input class='Input' placeholder='input'>
</div>

<div class='field'>
  <div class='select'>
    <select>
      <option>Option 1</option>
      <option>Option 2</option>
      <option>Option 3</option>
    </select>
  </div>
</div>

<div class='field'>
  <div class='checkbox'>
    <input type='checkbox' id='checkbox'></input>
    <label for='checkbox'> Checkbox </label>
  </div>
</div>

<div class='field'>
  <div class='radio'>
    <input type='radio' id='radio' name='radio'></input>
    <label for='radio'> Checkbox </label>
  </div>
  <div class='radio'>
    <input type='radio' id='radio2' name='radio'></input>
    <label for='radio2'> Checkbox </label>
  </div>
</div>

 <textarea></textarea>

 */

::-webkit-input-placeholder {
  color: $mediumgrey;
}

:-moz-placeholder { /* Firefox 18- */
  color: $mediumgrey;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $mediumgrey;
}

:-ms-input-placeholder {
  color: $mediumgrey;
}

.field,
.radio,
.checkbox {
  width: 100%;
  float: left;
}

.radio,
.checkbox {
  margin: 0 0 14px 0;
}


select::-ms-expand {
  display: none;
}

.select-field {
  position: relative;
}

.select-field:after {
  //content: '\e62a';
  //font-family: 'icomoon';
  @extend .far;
  position: absolute;
  right: 20px;
  color: $mediumgrey;
  line-height: 1;
  content: fa-content($fa-var-angle-down);
}

.field {
  margin-bottom: 1em;
}

.select {
  position: relative;

  select {
    min-width: 80px;
  }
}

// Overrides
/**

 */

.v-select.vs--searchable,
.v-select.fs--unsearchable {
  .vs__dropdown-toggle {
    border: 1px solid lighten(color('grey'), 10%);
  }

  &.vs--open {
    .vs__dropdown-menu,
    .vs__dropdown-toggle {
      border-color: lighten(color($primary), 20%) !important;
      box-shadow: 0 0 0 2px lighten(color($primary), 20%);
    }
  }
}

.Form-field .v-select .vs__open-indicator {
  &:before {
    @extend .far;
    content: fa-content($fa-var-chevron-down);
    border: none;
    transform: none;
    display: inline-block;
    height: 15px;
    text-align: center;
    line-height: 15px;
    width: 15px;
  }
}

.Form-field .v-select.vs--open .vs__open-indicator:before {
  transform: rotate(180deg);
}

.v-select.vs--searchable .vs__dropdown-toggle, .v-select.vs--unsearchable .vs__dropdown-toggle {
  //padding: 0;

  min-height: 40px;
  height: auto;

  .vs__selected-options {
    //height: 40px;
    align-items: center;
    border: none !important;
  }

  //.selected-tag {
  //  //margin: 0;
  //  background: rgba(0, 105, 205, 0.24);
  //  font-size: 13px;
  //  height: 30px;
  //  color: color($primary-color);
  //  font-weight: $font-weight-heavy;
  //  border-radius: 3px;
  //}

  input {
    margin-top: -1px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.Form-field {
  input, select {
    margin: 0;
  }
}

//End Overrides

.select:after {
  //content: '\e62a';
  //font-family: 'icomoon';
  @extend .far;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  color: $mediumgrey;
  font-size: 0.75rem;
  content: fa-content($fa-var-angle-down);
}

nav.filter li a,
.field label,
label,
.label {
  //color: $mediumgrey;
}

.field .label,
.field label {
  display: inline-block;
  margin-bottom: $space-xs;
}

.field label:focus,
.field label:hover {
  color: $darkgrey;
}


/* Multiselector (checkboxes multiselector for industries etc) */
