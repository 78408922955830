
// .page-dashboard .Container {
//   max-width: 1280px;
//   margin: 0;
// }

.Container {
  display: block;
  width: 100%;
  //padding-left: 16px;
  //padding-right: 16px;
  max-width: 1344px;
  @include respond-to(xs) {
    margin: auto;
    //width: $container-width;
    //margin: $gutter / 2 auto;
  }

  @include respond-to(md) {
    //max-width: 1024px;
  }

  @include respond-to(lg) {
    //max-width: 80.063em;
    margin: auto;
  }

  @include respond-to(lg, min-width) {
    margin: auto;
  }
}
.Row {
  flex-direction: row;
  width: 100%;
  &.Row--reverse {
    flex-direction: row-reverse;
  }

  &.Row--centerItem {
    justify-content: center;
  }
  &.Row--gutterLess {
    padding-left: 0;
    .Col{
      padding-left: 0;
    }
  }
}

.Column {
  flex-direction: column;
  &.Column--reverse {
    flex-direction: column-reverse;
  }
}

.no-flexbox {
  .Row,
  .Column {
    display: block;
    @extend .clearfix;
  }
}

.Row,
.Column {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.no-padding {
    [class^='Col-'],
    [class*='Col-'] {
      padding: 0;
    }
  }

  > .Row-title {
    display: block;
    width: 100%;
    padding-right: $space-default;
    padding-left: $space-default;
  }

  .box-row {
    position: relative;
    height: 100%;
    min-height: 40px;
    background: #ddd;
  }

  &.no-gutter {
    margin: 0;
    [class*='Col-'], [class^='Col-'] {
      margin-bottom: spacing();
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.duration-zone {
  .Container {
    //background: #fff;
  }
}

body {
  background: #fff;
  &.page-dashboard,
  &.control-panel {
    background: #fff;
  }
}

.SearchFilters {
  //IE9
  width: 25% \9
;
  padding-top: 0;
  padding-bottom: 0;

  @include respond-to(md) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .search-filteration {
    padding: 1em \9;
    background: #fff;
    @include respond-to(md) {
      height: 100%;
      padding: 1em;
    }
  }
}

.MegaSearch {
  margin-bottom: 0;
}

.content.page-company-az {
  .MegaSearch {
    .top-header {
      margin: 0;
    }
  }
}
//@media screen and (max-width: 1089px) {
//  main {
//    padding-top: 70px;
//  }
//}
@media screen and (max-width: 600px) {
  .list-view .Col-xs-12,
  .Col-sm-12 {
    padding-right: 0;
    padding-left: 0;
    > .Block {
      border-right: none;
      border-left: none;
    }
  }
}

main {
  padding-top: 50px;
  @include respond-to(sm) {
    padding-top: 0;
  }
}