.SearchInputSearchLocation {
  input {
    width: 100%;
  }
}

//TOdo move to its own file
.Form-inputBox,
.SearchInput {
  position: relative;

  .LoadingScreen {
    width: 30px;
    height: 30px;
    background: transparent;
    top: 7px;
    left: auto;
    right: 90px;

  }

  .LoadingScreen-spinner {
    position: relative;
    top: 0;
    transform: none;
    display: block;
    left: 0;
  }

  .Button {
    position: absolute;
    right: 0px;
    top: 0;
    box-shadow: none;
    border-radius: 0 4px 4px 0;
    padding: 0 spacing();

    &:focus,
    &:hover {
      transform: none;
      color: color('white');
    }

    span {
      font-size: 20px;
      line-height: 45px;
    }
  }
}

.Form-inputBox {
  .LoadingScreen {
    //top: 17px;
    transform: none;
    right: spacing(sm);
  }
}

.ResetInput {
  position: absolute;
  top: 0;
  width: 30px;
  height: 40px;
  z-index: 4;
  right: 0;
  text-align: center;
  line-height: 40px;

  span {
    font-size: 14px;
    color: #a39696;
  }
}

.avatar {
  height: 25px;
  width: 25px;
  border-radius: 20px;
  margin-right: 10px;
}

.SearchInput input {
  margin: 0 !important;
  outline: none;
  position: relative;
  display: block;
  //border: 1px solid color("grey", "light");
  padding: 10px;
  width: 100%;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  margin: 0;
  top: 100%;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow: auto;
  margin-top: -3px;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results_item {
  cursor: pointer;
  padding: 0.5rem 15px;
  color: #ea5b1b;

  strong {
    //color: color($primary-color);
  }
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  font-weight: $font-weight-heavy;
  font-size: 1.2em;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-bottom: 1px solid color("grey", "light");
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results_item.autosuggest__results_item-highlighted {
  background-color: color($primary-color);
  color: color('white');
  strong {
    color: #fff;
  }
}
