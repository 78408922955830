.Form-field {
  margin-bottom: spacing(lg);
  text-align: left;
  @include clearfix;
}

.field { //Legacy
  width: 100%;
  float: left;
}

.field, .Form-field {
  input, select, textarea { //Legacy
    width: 100%;
  }
}
