.Form-input,
input {
  @include input-default();
  @include input-error();
  @include input-focus();
  @include disabled();
  height: 48px;
  line-height: 46px;
  margin: 0;
  padding: 0 $space-default;
  font-family: $font-default;
}


