/// Center positioning - works with absolute position
/// @example
///   @include center('both');
/// @param {type} $position [none] - `both`, `horizontal`, `vertical`
@mixin center($position) {
  @include position(absolute);
  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  } @else if $position == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/// position
/// @example
///   @include position(absolute, $top: 10px;);
/// @param {type} $breakpoint [none] - `position(relative, absolute, static, fixed)`, `$top`, `$right`, `$bottom`, `$left`,
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
