/// Z-indexes
$indexes: (
  overlay: 3,
  header: 2,
  dropdown: 4,
  modal: 5,
  footer: 1,
  content: 1,
  behind: -1,
);

/// Z-index
/// @example
///   @include z-index('over');
/// @param {type} $indexes [none] - `overlay`, `header`, `dropdown`, `modal`, `footer`, `content`, `behind`
@mixin z-index($index) {
  @if not map-has-key($indexes, $index) {
    @throw 'Invalid breakpoint `#{$index}`. Choose one of `#{map-keys($indexes)}`.';
  }
  z-index: #{map-get($indexes, $index)};
}
