
.register.new-signup,
.page-write-review,
.page-dashboard {
  .select2-container {
    margin: 0;
    border: 1px solid $mediumgrey;
    @include border-radius(4px);
  }
}

.dropdown-wrapper {
  @include z-index(content);
}

.select2-dropdown {
  width: calc(100% + 2px);
}

.select2-container {
  margin-top: -68px;
  &.select2-container--open {
    border-color: transparent;
    box-shadow: 0 0 0 3px #1a90ff;
  }
}

.select2-container--default .select2-selection--single {
  padding: 20px 20px;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border-color: #838789 !important;
  box-shadow: none;
}
