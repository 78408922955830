.UserIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0px 5px 0 0 !important;
  color: color($primary-color);
  border-radius: 20px;
  font-size: 1.25rem;
  //line-height: 18px !important;
  text-align: center;
  vertical-align: middle;
}

.userDetails {
  display: inline-block;
  font-weight: 700;
  vertical-align: middle;
}


header#pageheader .AccountNav {
  .UserMenu {
    font-size: 17px;
    display: inline-block;
    line-height: 10px;
    position: relative;
    top: 6px;
  }

  li.subnav {
    padding: 22px 0 24px 15px;
    font-size: 0;

    span {
      margin: 0;
    }

    span.Icon-r Icon-angle-down {
      display: inline-block;
      vertical-align: middle;
    }

    span.name {
      display: inline-block;
      min-width: 24px;
      max-width: 80px;
      margin-right: 6px;
      font-size: 12px;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
    }

    ul {
      right: 0;
      left: auto;
      width: 160px;

      a {
        display: block;
        padding: 10px;
        color: color('white');
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &.UserLoggedIn {
      position: relative;
      cursor: pointer;
    }
  }
}

#inbox-notice ul {
  display: flex;
  align-items: center;
  //@include respond-to(md) {
  //  display: block
  //}
}

@media screen and (max-width: 736px) {
  .userDetails {
    padding-right: 8px;
    .ProgressBar,
    .UserMenu,
    .name {
      display: none!important;
    }
  }
  header#pageheader .AccountNav .UserMenu {
    top: 3px;
  }

  header#pageheader .AccountNav li.subnav:hover ul {
    top: 61px;
  }
}
