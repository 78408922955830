.Hint {
  margin: spacing(xs) 0 0;
  color: color('grey');
  font-size: 13px;
}

.Hint-icon {
  margin-right: spacing(xs);

  // TODO: ovverrides so RMA scss plays nice with Rate
  //&::before {
  //  font-size: 16px;
  //  @include fa-icon;
  //  color: color($primary);
  //  @extend .far;
  //  content: fa-content($fa-var-info-circle);
  //}
}

.Hint--warning {
  .Hint-icon {
    margin-right: spacing(xs);

    // TODO: ovverrides so RMA scss plays nice with Rate
    //&::before {
    //  font-size: 16px;
    //  @include fa-icon;
    //  color: color('orange');
    //  @extend .far;
    //  content: fa-content($fa-var-info-circle);
    //}
  }
}
