$themePostFeaturedImageNormalSize: 400px;
$themePostFeaturedImageSmallSize: 225px;

@import '../../abstracts/abstracts';
@import 'sidebar';
@import 'author';

//Custom
.AccountNav .HeaderCount-container {
  //display: none !important;
}

body {
  padding: 0 !important;
}

.blog {

  main {
    padding-top: 40px;
  }

  &.is-category {

  }

  &.is-post {

  }

  .RMP-Post-FeaturedImage {
    img, picture {
      object-fit: cover;
      width: 100%;
      height: $themePostFeaturedImageNormalSize;
    }

    margin-bottom: 20px;
  }

  .RMP-Post-Date {
    margin-bottom: 0;
  }

  .RMP-Post-Title {

  }

  @media screen and (max-width: $tablet) {
    .RMP-Post-FeaturedImage {
      img, picture {
        height: $themePostFeaturedImageSmallSize;
      }
    }
  }

  .RMP-PostTease {
    .RMP-PostTease-Title {
      line-height: 1.2;
      padding-top: 10px;
      margin-bottom: 10px;

      a {
        color: $darkgrey;
      }

    }

    .RMP-PostTease-Meta {
      font-size: 80%;
    }

    .RMP-PostTease-MetaItem {
      margin-right: 10px;
    }

    .RMP-PostTease-Excerpt {
      margin-bottom: 0;

      &.is-api {
        p {
          margin-bottom: 0;
        }
      }
    }

    .RMP-PostTease-Image {
      img, picture {
        object-fit: cover;
        width: 100%;
        height: $themePostFeaturedImageSmallSize;
      }
    }
  }


  @media screen and (max-width: $tablet) {
    .RMP-PostTease {
      .RMP-PostTease-Image {
        img, picture {
          height: 150px;
        }
      }

      .p-lg {
        padding: 1rem;
      }
    }
  }

  .RMP-PostContent {
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }
  }

}

.wp-block-search__label {
  display: none;
}

.breadcrumb {
  font-size: 14px;
  padding: 16px 0;

  .Col-sm-12 {
    padding-bottom: 0;
  }
}

.BlogSearchResults {
  .Card {
    margin-bottom: spacing();
    display: block;
    width: 100%;
  }
}

.Article {
  margin-top: spacing(lg);
  margin-bottom: spacing(xl);

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
  }
}


//Override

#pageheader .HeaderCount {
  @include respond-to(sm, 'min-width') {
    padding: 19px 12px !important;
    cursor: pointer;
  }
}

.RMP-MainContent {
  .wp-block-button__link {
    @extend .Button;
    //line-height: 1.3;
    &:hover {
      //background: darken(var(--wp--custom--color--green), 1);
    }
  }
}

.wp-block-button__link {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px !important;
}

.Article {
  .wp-block-embed__wrapper {
    text-align: center;
  }
}

.RMP-JobsWidget {
  margin: spacing() 0;

}

.RMP-JobsWidget--Job {
  border-radius: 6px !important;
}

.Notification-trigger {
  padding: 0 12px;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 1.25rem;
}

.is-opened .Nav {
  top: 50px;
  transform: translateX(0);
  height: auto;
}

.MegaSearch {
  @include respond-to(sm, 'max-width') {
    margin-top: 50px;
  }
}

.aside .menu {
  width: 100%;
}

.RMP-JobsWidget--Results {
  display: flex;
  flex-wrap: wrap;
}