/// @group Utility Padding
.u-noPadding {
  padding: 0 !important;
}

/// @group Utility Padding
.u-noPaddingTop {
  padding-top: 0 !important;
}

/// @group Utility Padding
.u-noPaddingBottom {
  padding-bottom: 0 !important;
}

/// @group Utility Padding
.u-noPaddingLeft {
  padding-left: 0 !important;
}

/// @group Utility Padding
.u-noPaddingRight {
  padding-right: 0 !important;
}
