/* Option Picker (replacement for selects) */
.js #content .optionpicker {
  overflow: hidden;
  zoom: 1;
}

.js #content .optionpicker li {
  margin-bottom: 4px;
  margin-left: 0;
  float: left;
  list-style: none;
}

.js #content .optionpicker span {
  display: inline-block;
  margin-right: 4px;
  padding: 4px 6px 6px;
  border-radius: 4px;
  background-color: #eee;
  color: color('grey', 'dark');
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-right: 4px;
  background-color: color('grey', 'lightest');
  color: color('grey');
}

.js #content .optionpicker span:hover {
  background-color: color('grey', 'lightest');
  color: #0a3555;
  text-decoration: underline;
}

.js #content .optionpicker .selected span {
  background-color: color($primary-color);
  color: color('white');
  text-decoration: none;
}

.js #content .optionpicker .first.selected span {
  background-color: color('red');
  color: color('white');
}


.control-panel select {
  margin: 0;
}
