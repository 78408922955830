@mixin checkbox-label(){
  margin-right: spacing(sm);
  float: left;
  font-size: 1rem;
  text-align: center;
  content: '';
}

.Form-field--checkbox,
.checkboxes {
  margin: spacing() 0;
}

.checkbox input[type='checkbox'], // Legacy Support
.Form-field--checkbox .Form-input {
  @include radio-check-input();
}

.checkbox label:before, //Legacy
.Form-field--checkbox .Form-label:before {
  @include radio-check-pseudo();
  @include checkbox-label();
  border-radius: radius(sm);
}

.Form-input:checked + .Form-label,
.checkbox input[type='checkbox']:checked + label {
  color: color($primary);
}

.Form-field--checkbox .Form-input:checked + .Form-label:before,
.field .checkbox input[type='checkbox']:checked + label:before {
  @include checked('checkbox');
}

// Search Stuff that uses checkboxes
.filter.checkboxes li.selected > a:before {
  @include checked('checkbox');
}

.is-disabled {
  label {
    color: color('grey') !important;

    &::before {
      background: color('grey', 'light') !important;
    }
  }
}

