/// @group Utility Hide
.u-hidden-xs {
  display: none !important;
  @include respond-to(xs) {
    display: block !important;
  }
}

.u-hidden-sm {
  @include respond-to(sm) {
    display: none !important;
  }
}

/// @group Utility Hide
.u-hidden-md {
  @include respond-to(md) {
    display: none !important;
  }
}

/*.u-hidden-l {
  display: block;
  @include respond-to(md) {
    display: none;
  }
  @include respond-to(lg) {
    display: block;
  }
}*/

/// @group Utility Hide
.u-hidden-lg {
  display: block !important;
  @include respond-to(lg) {
    display: none !important;
  }
}

.js .hidden {
  display: none;
}
