
// theming
// Reference to colours above
$primary:'orange'!default;
$primary-color: 'orange';
$border-color: #DDDDDD !default;
$accent-color: 'blue';
$error-color: 'red';
$homepagePrimary: $homepageOrange;

// BUTTONS
// Primary Button
$primary-button-background: color('green')!default;
$primary-button-background-hover: color('green', 'dark')!default;
$primary-button-text-color: color('white')!default;
$primary-button-text-color-hover: color('white')!default;

// Secondary Button
$secondary-button-background: color('grey', 'lightest') !default;
$secondary-button-background-hover: color('grey', 'light') !default;
$secondary-button-text-color: #282C34 !default;
$secondary-button-text-color-hover: #282C34 !default;

// Secondary Button
//$secondary-button-background: lighten(color($primary-color), 45) !default;
//$secondary-button-background-hover: color($primary-color) !default;
//$secondary-button-text-color: #282C34 !default;
//$secondary-button-text-color-hover: color('white') !default;

// Danger Button
$danger-button-background: color('red') !default;
$danger-button-background-hover: color('red', 'dark') !default;
$danger-button-text-color: color('white') !default;

// Tertiary Button
$tertiary-button-background: color('white') !default;
$tertiary-button-background-hover: color('orange') !default;
$tertiary-button-border-colour: color('orange') !default;
$tertiary-button-text-color: color('orange') !default;
$tertiary-button-hover-text-color: color('white') !default;

// Subtle Button
$subtle-button-background: rgba(0, 0, 0, 0.05) !default;
$subtle-button-background-hover:color('grey', 'lightest') !default;
$subtle-button-text-color: #282C34 !default;

// Text
$text-color: #222222;
$text-link: color($primary) !default;
$text-hover: color('green') !default;

$success-color: color('green');
$error-color: color('red');
$labelgrey: color('grey');

$rating-color: color('yellow');

//FONT
$font-weight-heavy: 700;
$fw-regular: 400 !default;