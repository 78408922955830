.Card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  border-radius: radius(md);
  display: inline-block;

  &.is-link:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12);
  }

  .text-link {
    font-weight: $font-weight-heavy;
  }
}

has-noBorder {
  border: none;
}

.Card-content {
  padding: 24px;
}

.Card-subheader {
  opacity: 0.7;
  font-size: 16px;
  margin-bottom: 16px;
  letter-spacing: 0.1px;
}

.Card-logo {
  border: 1px solid $border-color;
  border-radius: radius(sm);
  height: 60px;
  width: 60px;
  overflow: hidden;
  margin-bottom: 8px;

  img {
    object-fit: fill;
  }
}

.Card-footer {
  display: flex;
  //max-height: 48px;
  align-items: center;
  padding: 0 16px 24px;
  margin-top: auto;

  .text-link {
    &::after {
      content: none;
    }
  }
}

.CompanySearchListing {
  .Card-footer {
    padding-top: 16px;
  }
}

//Not for this file
.ShortlistIcon-icon {
  .ShortlistIcon-label {
    display: inline;
    font-size: inherit;
  }

  &.shortlisted {
    color: #ff5043;
  }

  &.Button--iconOnly {
    margin-left: auto !important;
    font-size: 24px;
    transform: translateX(8px);

    .Shortlist-label {
      display: none;
    }
  }
}

.RelatedContent {
  position: relative;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
}

.RelatedContent-items {
  display: flex;
  margin-bottom: 32px;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
}

.JobCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 280px;
  width: 100%;

  ul,
  li {
    margin: 0;
    margin-bottom: 4px;
    color: #222222;
  }

  li {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12);

    .text-link {
      color: color('green');
    }
  }
}

.CaseStudyCard {
  display: inline-block;
  width: calc(50% - 8px);
  justify-content: space-between;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 12px 28px 0 rgba(0, 0, 0, 0.12) !important;

    .text-link {
      color: color('green');
    }
  }
}

.hidden {
  visibility: hidden;
}

.RelatedJobsSlider,
.RelatedCaseStudiesSlider,
.RelatedEventsSlider,
.RelatedReviewsSlider {
  .RelatedJobsSlider-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
  }

  .splide__arrows {
    position: absolute;
    right: 0;
    top: 7px;
    display: flex;
    width: 70px;
    align-items: flex-start;
    justify-content: space-between;
    @include respond-to(xs, max-width) {
      display: none;
    }
    text-align: right;

    .splide__arrow--prev, .splide__arrow--next {
      display: inline;
      transform: none;
      font-weight: bold;
      //font-size: 1.5rem;
      border-radius: radius();
      background: #FFFFFF;
      border: 1px solid #EBECED;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.10), 0 4px 8px 0 rgba(0, 0, 0, 0.05);
      padding: 5px 10px;
      position: static;
    }

    .splide__arrow--next {
      margin-right: 0;
    }
  }
}

.splide {
  position: static;
}

.splide__slide {
  height: auto;
  margin: 0;
}

.splide__slides {
  overflow: visible;
}

.splide__track {
  overflow: visible;
}
