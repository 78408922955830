// Text Alignments
.u-textLeft {
  text-align: left;
}

.u-textRight {
  text-align: right;
}

.u-textCenter {
  text-align: center;
}
.u-center {
  text-align: center;
}

//Float  Todo float alignment
//u-floatLeft
.u-alignLeft {
  margin-right: auto;
  float: left;
}
//u-floatLeft
.u-alignRight {
  margin-left: auto;
  float: right !important;
}

.u-colCenter{
  display: block;
  align-self: center;
  margin: auto;
  float: none !important;
}

// Old
.valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.clearfix {
  @include clearfix;
}

@mixin valign($position) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}