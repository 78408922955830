/**
 * Clearfix
 */
@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';
  }

  &::after {
    clear: both;
  }
}
